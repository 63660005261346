import React, { useEffect, useRef, useState,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { duotoneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import axios from 'axios';

import { prefix } from "../siteConfig";
import { format } from 'date-fns';
import TextSelector from 'text-selection-react-llm';
import { FormControl } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { Radio, RadioGroup, FormControlLabel, FormLabel } from '@material-ui/core';

import { UserContext } from '../index'; 
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';



import {
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
} from "reactstrap";

function Dashboard(props) {
  const [selectedRow, setSelectedRow] = useState(null);
  const [leetCodeProblems, setLeetCodeProblems] = React.useState([]);
  const [chatMessages, setChatMessages] = React.useState([]);

  const [userInput, setUserInput] = useState('');
  const [userProblemHighlights, setUserProblemHighlights] = useState({});
  const [open, setOpen] = useState(false);
  const [anchorPos, setAnchorPos] = useState({ top: 0, left: 0 });

  const lastMessageRef = useRef(null);

  // const [startConvo, setStartConvo] = useState('askSolution');
  const [isThinking, setIsThinking] = useState(false);

  const [relativeBurden, setRelativeBurden] = useState("3")
  const [showRelativeBurden, setShowRelativeBurden] = useState(false)
  const [activateChat, setActivateChat] = useState(false)

  const navigate = useNavigate();
  const userContext  = useContext(UserContext);

  async function loadProblemDetails() {
    await getProblems(); // Wait for getProblems() to finish

    const queryParams = new URLSearchParams(window.location.search);
    const problemId = queryParams.get('problemId');
    if (problemId) {
        const problemItem = leetCodeProblems.find(item => parseInt(item.id) === parseInt(problemId));
        setSelectedRow(problemItem);
        handleStudyProblem();
    }
}


  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [chatMessages]);

  const handleClose = () => {
    setAnchorPos({ top: 0, left: 0 });
    setOpen(false);
  };

  const theme = createTheme({
    palette: {
      type: 'dark',
      secondary: {
        main: '#f50057',
      },
    },
  });

  useEffect(() => {
    getProblems();
  }, []);

  useEffect(() => {
    if (!userContext.ctx.has_email_schedule){
      toast.info(({ closeToast }) => (
        <div>
          Spaced repetition greatly improves long term retention. Click <span style={{fontWeight: 'bold', color: 'blue', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {
            addEmailScheduleBlind75();
            closeToast();
          }}>HERE</span> to opt into receiving a daily LeetCode study sheet via email. Or set a problem schedule manually <span style={{fontWeight: 'bold', color: 'blue', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {
            navigate('/admin/emailer')
            console.log('Manual scheduling clicked');
            closeToast(); // Close the toast if needed
          }}>HERE</span>.
        </div>
      ), {
        autoClose: false, 
        closeOnClick: false,
        // Ensure you allow HTML content or similar based on your toast configuration
      });
    }

  }, [userContext]);

  
  const addEmailScheduleBlind75 = async () => {
    try {
      const response = await fetch(`${prefix}content_personalization/add_email_schedule_blind75`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Include other headers as needed, like authorization tokens
        },
        // If you need to send any data in the request body, include it here
        // body: JSON.stringify({ key: 'value' }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      // Handle the response data
      console.log(data);
      // Optionally, show a success message or update the state to reflect the change
      toast.success('Successfully opted into receiving a LeetCode problem a day!');
    } catch (error) {
      console.error('Error:', error);
      // Optionally, show an error message
      toast.error('Failed to opt into receiving a LeetCode problem a day.');
    }
  };


  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const problemId = queryParams.get('problemId');
    if (leetCodeProblems && leetCodeProblems.length > 0 && problemId) {
        const intProblemId = parseInt(problemId, 10);
        const problemItem = leetCodeProblems.find(item => item.id === intProblemId);
        setSelectedRow(problemItem)
        handleStudyProblem(problemItem);
    }
  }, [leetCodeProblems]);

  useEffect(() => {
    console.log('userProblemHighlights changed:', userProblemHighlights);
  }, [userProblemHighlights]);

  useEffect(() => {
    if (!selectedRow?.problem) {
      setUserProblemHighlights([]);
      return;
    };

    fetch(`${prefix}content_personalization/getHighlights/${selectedRow.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        setUserProblemHighlights(data.highlights || []);
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [selectedRow?.problem]);

  const getProblems = async () => {
    const response = await fetch(`${prefix}leetcode/getProblems`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      const data = await response.json();
      setLeetCodeProblems(data);
    }
  };

  const handleStudyProblem = async (problem) => {
    console.log(selectedRow)
    setIsThinking(true);
    setChatMessages([]);

    if(selectedRow){
      problem = selectedRow
    }

    try {
      const response = await axios.post(`${prefix}content_personalization/studyProblem`, problem);
      setChatMessages(prevChatMessages => [...prevChatMessages, { role: 'system', message: response.data.message }]);
      setRelativeBurden(response.data.relative_burden.toString())
      setShowRelativeBurden(true)
      setActivateChat(true)

    } catch (error) {
      console.error('Error studying problem:', error);
    }
    setIsThinking(false);

  };

  const handleChat = async () => {
    setIsThinking(true);

    const input = userInput;
    setUserInput('');

    if (input) {
      setChatMessages(prevChatMessages => [...prevChatMessages, { role: 'user', message: input }]);
    }

    try {
      const response = await axios.post(`${prefix}content_personalization/chatProblem`, { userInput: input });
      setIsThinking(false);
      setChatMessages(prevChatMessages => [...prevChatMessages, { role: 'system', message: response.data.message }]);
    } catch (error) {
      console.error('Error studying problem:', error);
    }

  };

  const handleSaveHighlight = (html, text) => {
    fetch(`${prefix}content_personalization/saveHighlight`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text, problemId: selectedRow.id }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        const today = format(new Date(), 'MM-dd-yyyy');
        const updatedHighlights = {
          ...userProblemHighlights,
          [today]: userProblemHighlights[today] ? [...userProblemHighlights[today], text] : [text]
        };
        setUserProblemHighlights(updatedHighlights);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleCheckboxChange = async (problem) => {
    let updatedProblem = { ...problem, user_problem_highlights: { ...problem.user_problem_highlights, done: !problem.user_problem_highlights?.done } };

    try {
      const response = await axios.post(`${prefix}content_personalization/problemDone`, { problemId: updatedProblem.id, done: updatedProblem.user_problem_highlights.done });
      setLeetCodeProblems(leetCodeProblems.map(p => p.id === updatedProblem.id ? updatedProblem : p));
    } catch (error) {
      console.error(error);
    }
  };

  const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || 'markup')
      return !inline && match ? (
        <SyntaxHighlighter 
        lineProps={{style: {wordBreak: 'break-all' }}}
        wrapLines={true}
        style={duotoneDark} language={match[1]} PreTag="div" children={String(children).replace(/\n$/, '')} {...props} />
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      )
    }
  }

  // search/filter problems logic
  const [searchInput, setSearchInput] = useState('');
  const [selectedProblemFilter, setSelectedProblemFilter] = useState('NeetCode150');

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };
  const filteredProblems = leetCodeProblems.filter(problem => {
    const matchesSearchInput = problem.problem.toLowerCase().includes(searchInput.toLowerCase());
    let matchesSelectedFilter = selectedProblemFilter === 'All Available';
    if (selectedProblemFilter === 'NeetCode150') {
      matchesSelectedFilter = problem.neetcode150 !== null;
    } else if (selectedProblemFilter === 'Blind75') {
      matchesSelectedFilter = problem.blind75 !== null;
    }
    return matchesSearchInput && matchesSelectedFilter;
  });
  const handleProblemSelect = (filter) => {
    setSelectedProblemFilter(filter);
  };
  const patternOrder = ['Arrays & Hashing', 'Two Pointers', 'Stack', 'Binary Search', 'Sliding Window', 'Linked List', 'Trees', 'Tries', 'Heap / Priority Queue', 'Graphs', 'Backtracking', 'Intervals', 'Greedy', 'Advanced Graphs', '1-D Dynamic Programming', '2-D Dynamic Programming', 'Bit Manipulation', 'Math & Geometry', 'Uncategorized'];

  // hightlight logic
  const scrollContainerRef = useRef(null);
  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [userProblemHighlights]);

  const deleteHighlight = async (date, index) => {
    const response = await fetch(`${prefix}content_personalization/deleteHighlight`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        problemId: selectedRow.id,
        date: date,
        index: index,
      }),
    });

    const data = await response.json();

    if (data.status !== 'success') {
      console.error('Failed to delete highlight');
    } else {
      const updatedHighlights = { ...userProblemHighlights };
      updatedHighlights[date].splice(index, 1);
      if (updatedHighlights[date].length === 0) {
        delete updatedHighlights[date];
      }
      setUserProblemHighlights(updatedHighlights);
    }
  };
  const handleReviewHighlights = async () => {
    const response = await fetch(`${prefix}content_personalization/reviewHighlights`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        problemId: selectedRow.id,
        code: selectedRow.code
      }),
    });

    const data = await response.json();

    if (data.status !== 'success') {
      console.error('Failed to review highlights');
    } else {
      setChatMessages(prevChatMessages => [...prevChatMessages, { role: 'system', message: data.message }]);
    }
  };

  const handleRelativeBurdenChange = (e, value) => {
    setRelativeBurden(value.toString());

    fetch(`${prefix}content_personalization/updateRelativeBurden`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        problem_id: selectedRow.id,
        relative_burden: value
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col xs={{ order: 1 }} md={{ order: 0, size: 12 }} lg={{ order: 0, size: 4 }}>
            <Card className="card-tasks" style={{ height: '370px' }}>
              <CardHeader>
                <h6 className="title d-inline">LeetCode Problems</h6>
                <p className="card-category d-inline">
                  <input
                    className="problem-search-input"
                    type="text"
                    value={searchInput}
                    onChange={handleSearchChange}
                    placeholder="search"
                  />
                </p>
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="btn-icon"
                    color="link"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="tim-icons icon-bullet-list-67" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                    <DropdownItem
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleProblemSelect('NeetCode150');
                      }}
                    >
                      NeetCode150
                    </DropdownItem>
                    <DropdownItem
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleProblemSelect('Blind75');
                      }}
                    >
                      Blind75
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        handleProblemSelect('All Available');
                      }}
                    >
                      All Available
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CardHeader>
              <CardBody>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                  <div className="table-full-width table-responsive" style={{ height: '250px' }}>
                    <Table>
                      <tbody>
                        {filteredProblems.sort((a, b) => {
                          const patternAIndex = patternOrder.indexOf(a.pattern);
                          const patternBIndex = patternOrder.indexOf(b.pattern);

                          // If a pattern is not found in the patternOrder array, put it at the end
                          if (patternAIndex === -1) return 1;
                          if (patternBIndex === -1) return -1;

                          return patternAIndex - patternBIndex;
                        }).map((problem, index, self) => (
                          <>
                            {(index === 0 || problem.pattern !== self[index - 1].pattern) && (
                              <tr>
                                <td colSpan="2">{problem.pattern}</td>
                              </tr>
                            )}
                            <tr
                              key={problem.id}
                              style={{ backgroundColor: selectedRow?.problem === problem.problem ? '#32CD32' : '' }}
                              onClick={() => setSelectedRow(problem)}
                            >
                              <td>
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      checked={problem.user_problem_highlights?.done}
                                      onChange={() => handleCheckboxChange(problem)}
                                    />
                                    <span className="form-check-sign">
                                      <span className="check" />
                                    </span>
                                  </Label>
                                </FormGroup>
                              </td>
                              <td className="problem-cell">
                                <p className="title">{problem.problem}</p>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Divider style={{ height: '2px', backgroundColor: '#d3d3d3' }} />

                  <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <div>
                      <Button onClick={handleStudyProblem} variant="contained" style={{ fontSize: '0.9rem' }} size="small" color="primary">Learn</Button>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card style={{ height: '300px' }}>
              <CardHeader>
                <h6 className="title d-inline">Highlights</h6>
              </CardHeader>
              <CardBody>
                <div style={{ overflow: 'scroll', height: '200px' }} ref={scrollContainerRef}>
                  <Table className="tablesorter" responsive>
                    <tbody>
                      {Object.entries(userProblemHighlights || {}).map(([date, highlightList], index) => (
                        <React.Fragment key={index}>
                          <tr className="questions-highlight-date">
                            <td><strong>{date}</strong></td>
                          </tr>
                          {highlightList.map((highlight, index) => (
                            <tr className="questions-highlight" key={index}>
                              <td>{highlight}</td>
                              <td style={{ verticalAlign: 'top' }}>
                                <button
                                  onClick={() => deleteHighlight(date, index)}
                                  style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    padding: '5px',
                                    paddingTop: '0px',
                                    borderRadius: '50%',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    style={{ fontSize: '1.0em', color: 'darkgray' }}
                                  />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div style={{ textAlign: 'right', marginRight: '15px' }}>
                  <Button
                    onClick={handleReviewHighlights}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ fontSize: '0.7rem', padding: '6px 8px' }}
                    mb={5}
                    disabled={Object.keys(userProblemHighlights).length === 0}
                  >
                    review highlights
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs={{ order: 0 }} md={{ order: 1 }} lg={{ order: 1, size: 8 }}>
            <Card className="card-chart" sm="12" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '700px' }}>
              <CardHeader>
                <h6 className="title d-inline">Chat</h6>
              </CardHeader>
              <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{
                    flex: 1,
                    position: 'relative',
                    maxHeight: '440px',
                    padding: '20px',
                    flexDirection: 'column',
                    paddingBottom: 0
                  }}>
                  <Typography
                    // onMouseUp={handleMouseUp}
                    sx={{
                      border: 'none',
                      height: '16em',
                      overflow: 'auto',
                    }}
                    style={{ height: '390px', 
                    // whiteSpace: 'pre-wrap' 
                  }}
                  >
                    <TextSelector
                      events={[
                        {
                          text: 'Save Highlight',
                          handler: handleSaveHighlight
                        }
                      ]}
                      color={'yellow'}
                      colorText={true}
                    />
                    {chatMessages.map((chat, index) => (
                      // <div key={index} className={chat.role} ref={index === chatMessages.length - 1 ? lastMessageRef : null}>
                      <div key={index} className={`${chat.role}`} ref={index === chatMessages.length - 1 ? lastMessageRef : null}>

                        <ReactMarkdown components={components} children={chat.message} />
                      </div>
                    ))}
                    {!isThinking && chatMessages.length == [] && (
                      <Typography variant="body1" style={{ color: 'gray' }}>
                        <div style={{ marginBottom: '20px' }}>
                          Start a conversation by selecting a problem.
                        </div>
                        <div style={{
                          // position: 'absolute',
                          // top: '0%',
                          // left: '10px',
                          height: '20px',
                          width: '10px',
                          backgroundColor: 'green',
                          animation: 'blink 3s infinite'
                        }} />
                      </Typography>
                    )}
                    {isThinking && (
                      <Typography variant="body1" style={{ color: 'gray' }}>
                        <div style={{
                          // position: 'absolute',
                          // top: '0%',
                          // left: '10px',
                          height: '20px',
                          // width: '10px',
                          color: 'green',
                          animation: 'blink 1s infinite'
                        }}
                          ref={lastMessageRef} >Thinking...</div>

                      </Typography>
                    )}

                  </Typography>

                  <Popover
                    open={open}
                    anchorReference="anchorPosition"
                    anchorPosition={anchorPos}
                    onClose={handleClose}
                    onMouseDown={event => event.preventDefault()}
                    PaperProps={{
                      style: { backgroundColor: 'transparent', boxShadow: 'none' },
                    }}
                  >
                    <ThemeProvider theme={theme}>

                      <Button
                        onClick={handleSaveHighlight}
                        className="btn btn-sm"
                        size="small"
                        color="warning"
                      >Save Highlight</Button>
                    </ThemeProvider>
                  </Popover>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', padding: '15px' }}>
                  <p style={{ textAlign: 'right', fontSize: '0.8em', color: 'gray', mt: 10 }}>AI can make mistakes. Please double check suspicious answers.</p>

                  <TextField
                    sx={{
                      width: '100%',
                      boxSizing: 'border-box',
                      borderRadius: '8px',
                      backgroundColor: '#2d3658',
                      color: '#fff',
                      // padding:'15px',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                        },
                        '& input': {
                          color: '#fff',
                        },
                      },
                    }}
                    InputProps={{
                      style: {
                        color: 'lightgray',
                        fontSize: '16px',
                        padding: '15px'
                      },
                    }}
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    multiline
                    rows={3}
                    size="small"
                    onKeyDown={event => {
                      if (window.innerWidth >= 560 && event.key === 'Enter' && !event.shiftKey ) {
                        event.preventDefault();
                        handleChat();
                      }
                    }}
                  />
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px', alignItems: 'center' }}>

                  <div style={{ width: '350px' }}>

                  {showRelativeBurden && (

                    <FormControl component="fieldset" style={{ marginBottom: 0 }}>
                      <FormLabel style={{ color: 'gray', fontSize: '0.5rem', marginBottom: 0 }} component="legend">How easily can you solve this problem?</FormLabel>
                      <RadioGroup row aria-label="relativeBurden" name="relativeBurden" value={relativeBurden} onChange={(e, value) => {
                        if (value) {
                          handleRelativeBurdenChange(e, value);
                        }
                      }}>
                        <FormControlLabel
                          value="0"
                          control={<Radio size="small" style={{ padding: '5px' }}
                          />}
                          label={<Typography  style={{ fontSize: '0.5rem', marginBottom: 0 }} variant="body2">N/A</Typography>}
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio style={{ padding: '5px' }}
                            size="small" />}
                          label={<Typography style={{ fontSize: '0.5rem', marginBottom: 0 }} variant="body2">Easy</Typography>}
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio style={{ padding: '5px' }}
                            size="small" />}
                          label={<Typography style={{ fontSize: '0.5rem', marginBottom: 0 }} variant="body2">Medium</Typography>}
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio size="small" style={{ padding: '5px' }}
                          />}
                          label={<Typography style={{ fontSize: '0.5rem', marginBottom: 0 }} variant="body2">Hard</Typography>}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                  </div>
                    <Button
                      disabled={!activateChat}
                      color="secondary"
                      style={{ marginTop: '1rem', whiteSpace: 'nowrap' }}
                      onClick={handleChat}>
                      Chat
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
