// need REACT_APP_DEVELOPMENT (npm run build) *OR* process.env.NODE_ENV (npm run start)
export const prefix = process.env.REACT_APP_DEVELOPMENT || process.env.NODE_ENV === 'development' ? 
'http://localhost:4001/' : 'https://app.learnleetcode.com/';

// stripe
export const stripeKeyPublishable = process.env.REACT_APP_DEVELOPMENT || process.env.NODE_ENV === 'development' ?
'pk_test_51P9QfdAaFGNEHakGLvQJVn6SoljrKLwj2pMnGUpNKFhgVsKgehMVUyteAILZP6aOwaMrjpkYP241IKXbmul4mZA100cADfabdJ' : 'pk_live_51P9QfdAaFGNEHakGuqR8fG7SySnFfKkAOnHDFzu9BZraViVyPagBffAppQXBV7BD8HPmgHGtO3tRjYxbuWIsPP1y00pLqWcEqU';

// testing stripe on prod with dev key
// export const stripeKeyPublishable = process.env.REACT_APP_DEVELOPMENT || process.env.NODE_ENV === 'development' ?
// 'pk_test_51P9QfdAaFGNEHakGLvQJVn6SoljrKLwj2pMnGUpNKFhgVsKgehMVUyteAILZP6aOwaMrjpkYP241IKXbmul4mZA100cADfabdJ' : 'pk_test_51P9QfdAaFGNEHakGLvQJVn6SoljrKLwj2pMnGUpNKFhgVsKgehMVUyteAILZP6aOwaMrjpkYP241IKXbmul4mZA100cADfabdJ';