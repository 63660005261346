import React, { useEffect, useState } from 'react';
import { prefix } from "../siteConfig";
import { useLocation } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import { Select, MenuItem, CircularProgress, Box } from '@mui/material';

function Calendar(props) {
    const location = useLocation();
    const [leetCodeProblems, setLeetCodeProblems] = useState([]);
    const [selectedProblemFilter, setSelectedProblemFilter] = useState('NeetCode150');
    const [dateRange, setDateRange] = useState('2w');
    const [seriesData, setSeriesData] = useState([]);
    const [problemSet, setProblemSet] = useState('Blind75')
    const [calendarLoading, setCalendarLoading] = useState(false) 

    useEffect(() => {
      getProblems();
      getUserProblemCalendar()
      console.log("serieddata", seriesData);
    }, []);


    useEffect(() => {
      getUserProblemCalendar(problemSet,dateRange);
    }, [problemSet,dateRange]); 

    // useEffect(() => {
    //     if (leetCodeProblems.length > 0) {
    //         updateSeriesData();
    //     }
    // }, [leetCodeProblems, dateRange]);

    const getProblems = async () => {
        const response = await fetch(`${prefix}leetcode/getProblems`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        } else {
            const data = await response.json();
            setLeetCodeProblems(data);
        }
    };

    const getUserProblemCalendar = async () => {
      setCalendarLoading(true)
      const response = await fetch(`${prefix}content_personalization/userProblemCalendar?timeframe=${dateRange}&problemSet=${problemSet}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
      });
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
          setCalendarLoading(false)
      } else {
          const data = await response.json();

          // const transformedData = Object.entries(data).reduce((acc, [problemName, reviews]) => {
          //   const seriesData = reviews.map(review => ({ x: review.date_reviewed, y: review.relative_burden }));
          //   acc.push({
          //     name: problemName,
          //     data: seriesData
          //   });
          //   return acc;
          // }, []);
          // console.log("transformedData", transformedData)
          setSeriesData(data);
          setCalendarLoading(false)


          //return data;
      }
  };

    const filteredProblems = leetCodeProblems.filter(problem => {
        let matchesSelectedFilter = selectedProblemFilter === 'All Available';
        if (selectedProblemFilter === 'NeetCode150') {
            matchesSelectedFilter = problem.neetcode150 !== null;
        } else if (selectedProblemFilter === 'Blind75') {
            matchesSelectedFilter = problem.blind75 !== null;
        }
        return matchesSelectedFilter;
    });

      function transformData(data) {
        return data.reduce((acc, { problem: problemName, dates: reviews }) => {
          const seriesData = reviews.map(review => ({ x: review.date_reviewed, y: review.relative_burden }));
          acc.push({
            name: problemName,
            data: seriesData
          });
          return acc;
        }, []);
      }

      const patternOrder = ['Arrays & Hashing', 'Two Pointers', 'Stack', 'Binary Search', 'Sliding Window', 'Linked List', 'Trees', 'Tries', 'Heap / Priority Queue', 'Graphs', 'Backtracking', 'Intervals', 'Greedy', 'Advanced Graphs', '1-D Dynamic Programming', '2-D Dynamic Programming', 'Bit Manipulation', 'Math & Geometry', 'Uncategorized'];

  return (
    <div style={{ marginTop: '75px', marginLeft: '20px' }} >
      <div id="chart" style={{ maxWidth: '700px' }}>
        {/* <div style={{ display: 'flex-start', justifyContent: 'space-between', marginBottom: '20px' }}> */}
        <Box display="flex" alignItems="center">

          <Select
            value={problemSet}
            onChange={(e) => setProblemSet(e.target.value)}
            variant="outlined"
            sx={{ marginRight: '20px', width: '150px'  }}
          >
            <MenuItem value="Blind75">Blind75</MenuItem>
            <MenuItem value="NeetCode150">NeetCode150</MenuItem>
          </Select>
          <Select
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value)}
            variant="outlined"
            sx={{ marginRight: '30px', width: '150px'  }}
          >
            <MenuItem value="2w">Last 2 Weeks</MenuItem>
            <MenuItem value="1m">Last 1 Month</MenuItem>
            {/* <MenuItem value="3m">Last 3 Months</MenuItem>
            <MenuItem value="1y">Last Year</MenuItem> */}
          </Select>
          {calendarLoading && <CircularProgress size={24} />}
        </Box>
        {/* </div> */}

        {
          patternOrder.map((pattern) => {
            if (seriesData[pattern]) {
              // Object.keys(seriesData).map((pattern) => {
              const options = {
                chart: {
                  type: 'heatmap',
                  renderTo: 'chart',
                  zoom: {
                    enabled: false,
                  },
                  toolbar: {
                    show: false,
                  },
                  foreColor: 'white',
                },
                legend: {
                  show: false
                },
                plotOptions: {
                  heatmap: {
                    padding: {
                      top: 10,
                      right: 10,
                      bottom: 10,
                      left: 10
                    },
                    colorScale: {
                      ranges: [
                        { from: -1, to: 0, color: '#525F7F' }, // color for value 0
                        { from: 1, to: 1, color: '#007BFF' }, // color for value 1
                        { from: 2, to: 2, color: '#0056b3' }, // color for value 2
                        { from: 3, to: 3, color: '#003d80' }, // color for value 3
                      ],
                    },
                    background: '#525f7f'
                  }
                },
                title: {
                  text: pattern,
                  align: 'center',
                },
                dataLabels: {
                  enabled: false
                },

                stroke: {
                  colors: ["#212529"],
                },
                colors: ["#008FFB"],
                xaxis: {
                  type: "datetime"
                  // labels: {
                  //   format: 'MMM',
                  // showDuplicates: false,
                  // scrollbar: {
                  //   enabled: true
                  // }
                },

                yaxis: {
                  labels: {
                    style: {
                      colors: 'white', // color of the y-axis labels
                    }
                  },
                },
                tooltip: {
                  y: {
                    formatter: function(value) {
                      if (value === 1) {
                        return 'Easy';
                      } else if (value === 2) {
                        return 'Medium';
                      } else if (value === 3) {
                        return 'Hard';
                      } else if (value === 0) {
                        return 'Not Attempted';
                      }
                      return value;
                    }
                  }
                }
              };

              return (
                <div style={{ marginTop: '50px' }} key={pattern}>
                  <ReactApexChart
                    options={options}
                    series={transformData(seriesData[pattern])}
                    type="heatmap"
                    height={seriesData[pattern].length * 25 + (seriesData[pattern].length === 1 ? 10 : 0) + 73.21}
                  />
                </div>
              );
            }
          })
        }
      </div>
    </div>
  );
}

export default Calendar;
