import Dashboard from "views/Dashboard.js";
import Teacherbots from "views/Teacherbots";
import Calendar from "views/Calendar";
import Emailer from "views/Emailer";
import UserProfile from "views/UserProfile";
import TrialEnd from "views/TrialEnd";

import SignUp from "views/SignUp";
import { CheckoutForm, Return } from "views/PayStripe";

import Login from "views/Login";
import { prefix } from './siteConfig';


var routes = [
  {
    path: "/learn",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: <Dashboard />,
    layout: "/admin",
    sidebarDisplay: true
  },
  {
    path: "/teacherbots",
    name: "Teacherbots",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-heart-2",
    component: <Teacherbots />,
    layout: "/admin",
    sidebarDisplay: true
  },
  {
    path: "/calendar",
    name: "Calendar",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-calendar-60",
    component: <Calendar />,
    layout: "/admin",
    sidebarDisplay: true
  },
  {
    path: "/emailer",
    name: "Email Scheduler",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-email-85",
    component: <Emailer />,
    layout: "/admin",
    sidebarDisplay: true
  },
  {
    path: "/userSettings",
    name: "User Settings",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-02",
    component: <UserProfile />,
    layout: "/admin",
    sidebarDisplay: true
  },
  {
    path: "/signup",
    name: "SignUp",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-02",
    component: <SignUp />,
    layout: "/base",
  },
  {
    path: "/signup/return",
    name: "SignUp",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-02",
    component: <Return />,
    layout: "/base",
  },
  {
    path: "/signup/checkout",
    name: "Checkout",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-02",
    component: <CheckoutForm />,
    layout: "/base",
  },
  {
    path: "/login",
    name: "Login",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-02",
    component: <Login />,
    layout: "/base",
  },
  {
    path: "/trial-end",
    name: "TrialEnd",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-02",
    component: <TrialEnd />,
    layout: "/base",
  }
];
export default routes;
