import React, { useState, useEffect, useContext } from 'react'; // Import useContext here

import ReactSelect from 'react-select';
import TimezoneSelect, { type ITimezone, allTimezones } from "react-timezone-select"
import { prefix } from '../siteConfig'
import axios from 'axios';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Popover,
  PopoverBody,
  PopoverHeader
} from "reactstrap";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@material-ui/core/Box';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { CheckoutForm } from './PayStripe';
import { UserContext } from '../index';

import 'react-toastify/dist/ReactToastify.css';

function UserProfile() {
  const { ctx, setCTX } = useContext(UserContext);
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [reviewEmails, setReviewEmails] = useState(false);
  const [emailSchedules, setEmailSchedules] = useState([])

  const [visibleScheduleId, setVisibleScheduleId] = useState(null);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverTarget, setPopoverTarget] = useState(null);


  const togglePopover = (event) => {
    setPopoverTarget(event.currentTarget);
    setPopoverOpen(!popoverOpen);
  };

  const fetchUser = async (userContext) => {
    try {
      const response = await fetch(`${prefix}user_management/getUserAccess`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (!data.error && data) {
        console.log("setting Context")
        console.log(data)
        setCTX(prevCTX => ({ ...prevCTX, ...data }));
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    console.log("ctx")
    console.log(ctx)
  }, [ctx])

  useEffect(() => {
    fetch(`${prefix}user_management/userSettings`)
      .then(response => response.json())
      .then(data => {
        setSelectedTimezone(data.timezone);
        setReviewEmails(data.review_emails);
      });
    getEmailSchedules()
  }, []);

  useEffect(() => {
    console.log(selectedTimezone);
  }, [selectedTimezone]);

  const handleSave = () => {
    fetch(`${prefix}user_management/updateUserSettings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        timezone: selectedTimezone?.value,
        review_emails: reviewEmails
        // Include other fields as needed
      }),
    })
      .then(response => response.json())
      .then(data => {
        // Handle the response
        toast.success('Your changes have been saved.');
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error(error);
      });
  };

  const getEmailSchedules = () => {
    fetch(`${prefix}content_personalization/getEmailSchedules`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setEmailSchedules(data.email_schedules)
      })
      .catch((error) => {
        console.error('Error fetching email schedules:', error);
      });
  };
  const deleteSchedule = async (scheduleId) => {
    try {
      await axios.delete(`${prefix}content_personalization/deleteSchedule/${scheduleId}`);
      const updatedSchedules = emailSchedules.filter(schedule => schedule.id !== scheduleId);
      setEmailSchedules(updatedSchedules);
      toast.success('Schedule deleted successfully');
    } catch (error) {
      console.error('Failed to delete schedule', error);
    }
  };

  const toggleDetails = (id) => {
    setVisibleScheduleId(visibleScheduleId === id ? null : id);
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await fetch(`${prefix}user_management/cancelSubscription`, {
        method: 'POST', // or 'DELETE' depending on your API
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: 109 }), // Include any necessary data
      });

      if (response.ok) {
        await fetchUser()
        toast.success('Subscription cancelled successfully');
      } else {
        toast.error('Failed to cancel subscription. Contact admin@learnleetcode.com for support.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error cancelling subscription. Contact admin@learnleetcode.com for support.');
    }
  };
  const handleRestartSubscription = async () => {
    try {
      const response = await fetch(`${prefix}user_management/restartSubscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: 109 }),
      });

      if (response.ok) {
        await fetchUser()
        toast.success('Subscription restarted successfully');
      } else {
        // Handle errors
        toast.error('Failed to restart subscription. Contact admin@learnleetcode.com for support.');
      }
    } catch (error) {
      toast.error('Failed to restart subscription. Contact admin@learnleetcode.com for support.');
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="5">
          <Card>
            <CardBody>
              <CardText>
                <CardTitle tag="h3">User Settings</CardTitle>
                <h4>{ctx.email ? ctx.email : ''}</h4>
                <Form>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col className="pr-md-1" md="8">
                      <h6>Select Timezone</h6>
                      <div className="select-wrapper">
                        <TimezoneSelect value={selectedTimezone} onChange={setSelectedTimezone} timezones={{
                          ...allTimezones
                        }} />
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardText>
            </CardBody>
          </Card>
          <Card className="card-user">
            <CardBody>
              <CardText />
              <CardTitle tag="h3">Emails</CardTitle>
              <Row>
                <Col className="pr-md-1" md="8">
                  <h6>Problem Review Emails</h6>
                  <p className="helper-text">Receive review emails 2,4,8, and 16 days after you study a problem. Strengthen your knowledge by reviewing at strategic intervals.</p>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={reviewEmails}
                        onChange={e => setReviewEmails(e.target.checked)}
                      />
                    }
                    label="Get Problem Review Emails."
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: '20px' }}>
                <Col className="pr-md-1" md="8">
                  <Button onClick={handleSave}>Save</Button>
                </Col>
              </Row>
              <div>
                <h6>Schedules</h6>
                {emailSchedules && emailSchedules.length > 0 ? (
                  emailSchedules.map((schedule) => (
                    <Accordion key={schedule.id} expanded={visibleScheduleId === schedule.id} onChange={() => toggleDetails(schedule.id)}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>{schedule.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                          <Box>
                            <Typography component="div">
                              <p><h6>Start Date: {schedule.start_date}</h6></p>
                              <p><h6>Problems per Day: {schedule.num_per_day}</h6></p>
                              <p><h6>Paused: {schedule.paused ? 'Yes' : 'No'}</h6></p>
                              <p><h6>Problems Queued:</h6></p>
                              <ul>
                                {Array.isArray(schedule.problems_queued) && schedule.problems_queued.map((problem, index) => (
                                  <li key={index}>{problem}</li>
                                ))}
                              </ul>
                              <p><h6>Problems Sent:</h6></p>
                              <ul>
                                {Array.isArray(schedule.problems_sent) && schedule.problems_sent.map((problem, index) => (
                                  <li key={index}>{problem}</li>
                                ))}
                              </ul>
                            </Typography>
                          </Box>
                          <Box display="flex" justifyContent="flex-end" alignItems="flex-start" width="100%">
                            <IconButton aria-label="delete" onClick={() => deleteSchedule(schedule.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))
                ) : (
                  <Typography>No email schedules available.</Typography>
                )}
              </div>
            </CardBody>
          </Card>
          <Card className="card-user">
            <CardBody>
              <CardText />
              <CardTitle tag="h3">Subscription</CardTitle>
              <Row style={{ marginBottom: '20px' }}>
                <Col className="pr-md-1" md="8">
                  <div style={{ color: '#333', textAlign: 'left' }}>
                    {!ctx.sub_active ? (
                      <div>
                        <div style={{ marginBottom: '25px' }}>
                          <p>On <strong>trial</strong>, {ctx.days_left} days left. Subscribe below to continue access after end of trial.</p>
                        </div>
                        <div>
                          <CheckoutForm email={ctx.email} />
                        </div>
                      </div>
                    ) : ''}

                    {ctx.sub_active ? (
                      <div>
                        <p>Subscription is <strong>active</strong>.</p>
                        {ctx.active_subscription && !ctx.active_subscription.cancelled && (
                          <div>
                            <p>Your card will be charged on <strong>{ctx.active_subscription.current_period_end}</strong>.</p>
                            <p>Payment Method: <strong>{ctx.active_subscription.card_brand} ending in {ctx.active_subscription.card_last4}</strong></p>
                            <Button id="cancelSubscription" onClick={togglePopover} style={{ cursor: 'pointer', color: 'gray', background: 'none', border: 'none', padding: '0', textDecoration: 'underline' }}>
                              Cancel Subscription
                            </Button>
                            <Popover
                              placement="right"
                              isOpen={popoverOpen}
                              target={popoverTarget}
                              toggle={togglePopover}
                            >
                              <PopoverHeader>Confirm Cancellation</PopoverHeader>
                              <PopoverBody>
                                Are you sure you want to cancel your subscription? You will no longer get review emails or have access to the platform after the last day of your subscription.
                                <div style={{ marginTop: '10px' }}>
                                  <Button color="danger" onClick={handleCancelSubscription}>
                                    Yes, Cancel
                                  </Button>
                                  <Button color="secondary" onClick={togglePopover}>
                                    No, Keep it
                                  </Button>
                                </div>
                              </PopoverBody>
                            </Popover>

                          </div>
                        )}

                        {/* {ctx.active_subscription}
                        {ctx.active_subscription.cancelled }
                         */}
                        {ctx.active_subscription && ctx.active_subscription.cancelled && (
                          <div>
                            <p>Your subscription was cancelled. You have access to until <strong>{ctx.active_subscription.current_period_end}</strong>.</p>
                            <p>Payment Method: <strong>{ctx.active_subscription.card_brand} ending in {ctx.active_subscription.card_last4}</strong></p>
                            <Button onClick={handleRestartSubscription} style={{ cursor: 'pointer', color: 'gray', background: 'none', border: 'none', padding: '0', textDecoration: 'underline' }}>
                              Restart Subscription
                            </Button>
                          </div>
                        )}
                        <p>Contact admin[at]learnleetcode.com for support</p>
                      </div>
                    ) : ''}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default UserProfile;
