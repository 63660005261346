import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyADkDgI2Xl3XsTCorq8lSlR9kJ2h3CDB4I",
    authDomain: "teacherbot-a556c.firebaseapp.com",
    projectId: "teacherbot-a556c",
    storageBucket: "teacherbot-a556c.appspot.com",
    messagingSenderId: "444754062550",
    appId: "1:444754062550:web:2fbcbd4ba9ea55348e6d77",
    measurementId: "G-TKZW7RV7T1"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

setPersistence(auth, browserLocalPersistence)
  .catch((error) => {
    console.error("Error setting persistence: ", error);
  });

export default auth;