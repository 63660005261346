import React, { useState, useEffect, useContext } from 'react';
import { Container, Box, Typography, Button, Card, CardContent, List, ListItem, ListItemIcon, ListItemText, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CheckoutForm } from './PayStripe';
import { UserContext } from '../index'; 
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    background: {
      default: '#121212',
      paper: '#1d1d1d',
    },
    text: {
      primary: '#ffffff',
      secondary: '#b0bec5',
    },
  },
});

const SubscribePage = () => {
  const [open, setOpen] = useState(false);
  const userContext =  useContext(UserContext);
  const email = userContext && userContext.ctx.email ? userContext.ctx.email : ''

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=> {
    console.log( "userContext")
    console.log( userContext )
  },[userContext])


  const features = [
    'Daily review emails',
    'Utilize the power of spaced repetition',
    'Personalized learning',
    'Active learning. Get asked questions, simulating interview setting',
    'Save highlights and be tested on them later',
    'Calendar to keep track of problems done'
  ];

  return (
    <ThemeProvider theme={darkTheme}>
      <Container maxWidth="lg" style={{ marginTop: '50px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h4" component="h1" gutterBottom>
                  Subscribe
                </Typography>
                <Typography variant="body1" paragraph>
                 Thank you for trying LearnLeetCode for the last 30 days. Subscribe to Continue Using LearnLeetCode. Only $7/month. 
                </Typography>
                <List>
                  {features.map((feature, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <CheckCircleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={feature} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <CheckoutForm email={email} />
                {userContext.ctx.sub_active ? (
                   <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<ArrowForwardIcon />}
                      href="/admin/learn"
                      style={{ marginTop: '20px' }}
                    >
                      Continue to Dashboard
                    </Button>
                  </div>
                ) : (
                  ''
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default SubscribePage;