import React, { useEffect, useState, useContext } from 'react';
import { prefix } from 'siteConfig';
import { GoogleSignup } from './GoogleLogin';

import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@material-ui/core/Divider';

import Iconify from '../components/iconify';

import { toast } from 'react-toastify';
import validator from 'validator';
import { Link } from 'react-router-dom';

import { UserContext } from '../index'; 



import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBIcon
}
    from 'mdb-react-ui-kit';

function SignUp(props) {
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const navigate = useNavigate();

    const userContext  = useContext(UserContext);

    useEffect(() => {
        if(userContext.ctx.email){
            navigate('/admin/learn');
        }
    },[userContext])

    const googleSignup = GoogleSignup("/admin/learn");
    function validatePassword(password) {
        if (!validator.isLength(password, { min: 8, max: undefined })) {
            toast.error('Password should be at least 8 characters');
            return false;
        }
        return true;
    }

    const fetchUser = async () => {
        try {
          const response = await fetch(`${prefix}user_management/getUserAccess`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const data = await response.json();
          console.log("data: ", data);
          if (!data.error && data) {
            //.setCTX(data);
            userContext.setCTX(prevCTX => ({ ...prevCTX, ...data }));

            toast.info(
                'Welcome to LearnLeetCode: get started by choosing a problem and clicking Learn.',
                {
                    autoClose: false, 
                    closeOnClick: false,
                    pauseOnHover: false,
                }
            );
            navigate(`/admin/learn`);
          }
        } catch (error) {
          console.error("Failed to fetch user access:", error);
        }
      };
    const createUser = async (event) => {
        event.preventDefault();
        if (!validatePassword(password)) {
            return;
        }
        if (password !== repeatPassword) {
            toast.error('Passwords do not match');
            return;
        };
        try {
            const response = await fetch(`${prefix}user_management/createUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });
            const data = await response.json();
            if (!response.ok) {
                toast.error(data.error);
            } else {
                fetchUser();
            }
        } catch (error) {
            toast.error(error);
        }
    };

    return (
        <MDBContainer className="LoginForm" fluid>
            <MDBRow className='d-flex justify-content-center h-100'>
                <MDBCol col='12'>
                    <MDBCard className='bg-dark text-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '400px' }}>
                        <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>
                            <h2 className="fw-bold mb-2 text-uppercase">SIGNUP</h2>
                            <p className="text-white-50 mb-4">Use your Google account to sign up for learnleetcode.com</p>
                            <Button
                                fullWidth
                                size="large"
                                color="primary"
                                variant="outlined"
                                onClick={async () => {
                                    try {
                                        await googleSignup();
                                    } catch (error) {
                                        console.log('ErrorLogin:', error);
                                    }
                                }}
                            >
                                <Iconify icon="eva:google-fill" color="#DF3E30" />
                            </Button>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '20px 0' }}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Divider style={{ backgroundColor: 'darkgrey' }} />
                                </Box>
                                <Box sx={{ padding: '0 10px' }}>
                                    <Typography variant="caption" color="white">or</Typography>
                                </Box>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Divider style={{ backgroundColor: 'darkgrey' }} />
                                </Box>
                            </Box>
                            <MDBInput wrapperClass='mb-4 mx-5 w-100' inputStyle={{ padding: '10px' }} labelClass='text-white' label='Email address' id='formControlLg' type='email' size="lg" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <MDBInput wrapperClass='mb-4 mx-5 w-100' inputStyle={{ padding: '10px' }} labelClass='text-white' label='Password' id='formControlLg' type='password' size="lg" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <MDBInput wrapperClass='mb-4 mx-5 w-100' inputStyle={{ padding: '10px' }} labelClass='text-white' label='Password' id='formControlLg' type='password' size="lg" value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} />
                            <MDBBtn onClick={createUser} outline className='mx-2 px-5 signup-button' color='white' size='lg'>
                                Sign Up
                            </MDBBtn>
                            <div>
                                <p className="mb-0">Already have an account? <Link to="/login" className="text-white-50 fw-bold">Log In</Link></p>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );

}

export default SignUp;
