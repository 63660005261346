import React, { useEffect, useState } from 'react';

import { useTheme } from '@material-ui/core/styles';

import { prefix } from "../siteConfig";

import { MdVisibility } from 'react-icons/md';

import { FaQuestionCircle } from 'react-icons/fa'; // import question icon from react-icons

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    Form,
    Tooltip
} from "reactstrap";

function Teacherbots(props) {
    const [teacherbots, setTeacherbots] = useState([]);
    const [selectedTeacherbot, setSelectedTeacherbot] = useState({ name: '', prompt: '', active: false});
    const [mode, setMode] = useState('addNew');

    const [newName, setNewName] = useState('');
    const [newPrompt, setNewPrompt] = useState('');

    const [activeTeacherbot, setActiveTeacherbot] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);


    const handleRadioChange = async (event) => {
        if (event.target.value === null || event.target.value === 'none') {
            setActiveTeacherbot(null);
        } else {
            const selectedTeacherbot = teacherbots.find(teacherBot => teacherBot.name === event.target.value);
            setActiveTeacherbot(selectedTeacherbot);
        }
    
        const response = await fetch(`${prefix}content_personalization/updateActiveTeacherbot`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              activeTeacherBot: event.target.value,
            }),
        });
    
        if (!response.ok) {
            console.error('Failed to change activeTeacherBot on the backend');
        }
    };

    useEffect(() => {
        fetch(`${prefix}content_personalization/getTeacherbots`)
            .then(response => response.json())
            .then(data => {
                setTeacherbots(data);
                const activeTeacherbot = data.find(teacherbot => teacherbot.active);
                setActiveTeacherbot(activeTeacherbot || null);
            });
    }, [props.userId]);

    const handleAddNewMode = () => {
        setSelectedTeacherbot({ name: '', prompt: '' });
        setMode('addNew');
    };

    const handleAddNew = () => {
        fetch(`${prefix}content_personalization/addTeacherbot`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: newName,
                prompt: newPrompt,
            }),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setTeacherbots(prevTeacherbots => [...prevTeacherbots, data]);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
    return (
        <>
            <div className="content">
                <Row>
                    <Col lg="4" md="12">
                        <Card className="card-tasks" style={{ height: '370px' }}>
                            <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h6 className="title d-inline">TeacherBots</h6>
                                <FaQuestionCircle  style={{mt: '-1px' }}  id="Tooltip1" />
                                <Tooltip placement="right" isOpen={tooltipOpen} target="Tooltip1" toggle={toggle}>
                                    A Teacherbot helps you learn by taking on a persona and stye of communication that resonates with you. Joan is a default teacherbot based on Joan Rivers. Some copywriters use this persona to help them write copy because of her direct and oddly compelling style of speaking. Experiment and create your own. Comedians often work well. 
                                </Tooltip>
                            </CardHeader>
                            <CardBody>
                                <div style={{ overflow: 'scroll', height: '250px' }}>
                                    <Table className="tablesorter" responsive>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Active</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {teacherbots.map((teacherBot) => (
                                                <tr key={teacherBot.name}>
                                                    <td>
                                                        {teacherBot.name}
                                                        <Button
                                                            color="link"
                                                            onClick={() => {
                                                                setSelectedTeacherbot(teacherBot);
                                                                setMode('viewExisting');
                                                            }}
                                                        >
                                                            <MdVisibility />
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <input
                                                            name="teacherbot"
                                                            type="radio"
                                                            value={teacherBot.name}
                                                            checked={activeTeacherbot && activeTeacherbot.name === teacherBot.name}
                                                            onChange={handleRadioChange}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td><i style={{ fontSize: '0.8em' }}>none</i></td>
                                                <td>
                                                    <input
                                                        name="teacherbot"
                                                        type="radio"
                                                        value='none'
                                                        checked={activeTeacherbot === null}
                                                        onChange={handleRadioChange}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

          

                    <Col lg="4" md="12">
                        <Card className="card-tasks" style={{ height: '370px' }}>
                            <CardHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h6 className="title d-inline">View/Create Teacherbot</h6>
                                <div>
                                    {/* {selectedTeacherbot && selectedTeacherbot.name && <><a onClick={handleEdit}>Edit</a> | </>} */}
                                    <p style={{cursor : 'pointer', color: '#e14eca' }} onClick={handleAddNewMode}>Add New</p>                                </div>
                            </CardHeader>
                            <CardBody>
                                <div style={{ overflow: 'scroll', height: '250px' }}>
                                    <Form>
                                        {mode === 'viewExisting' && (
                                            <div>
                                                <FormGroup>
                                                    <Label for="name">Name</Label>
                                                    <Input type="text" name="name" id="name" placeholder="Enter name" value={selectedTeacherbot.name} readOnly />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="prompt">Prompt</Label>
                                                    <Input type="textarea" name="prompt" id="prompt" placeholder="Enter prompt" rows={5} value={selectedTeacherbot.prompt} readOnly />
                                                </FormGroup>
                                            </div>
                                        )}
                                        {/* {mode === 'edit' && (
                                            <div>
                                                <FormGroup>
                                                    <Label for="name">Name</Label>
                                                    <Input type="text" name="name" id="name" placeholder="Enter name" value={selectedTeacherbot.name} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="prompt">Prompt</Label>
                                                    <Input type="textarea" name="prompt" id="prompt" placeholder="Enter prompt" rows={5} value={selectedTeacherbot.prompt} readOnly={mode === 'edit'} />
                                                </FormGroup>
                                            </div>
                                        )} */}
                                        {mode === 'addNew' && (
                                            <div>
                                                <FormGroup>
                                                    <Label for="name">Name</Label>
                                                    <Input type="text" name="name" id="name" placeholder="Enter name" value={newName} onChange={e => setNewName(e.target.value)} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="prompt">Prompt</Label>
                                                    <Input type="textarea" name="prompt" id="prompt" placeholder="Enter prompt" rows={5} value={newPrompt} onChange={e => setNewPrompt(e.target.value)} readOnly={mode === 'edit'} />
                                                </FormGroup>
                                            </div>
                                        )}
                                    </Form>
                                </div>
                                <div className="text-right">
                                    {/* {mode === 'edit' && <Button color="primary" onClick={handleEdit}>Edit</Button>} */}
                                    {mode === 'addNew' && <Button color="primary" onClick={handleAddNew}>Add New</Button>}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Teacherbots;
