import React from 'react';
import { useNavigate } from 'react-router-dom';


import { useState, useEffect, useContext } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';

import Iconify from '../components/iconify';

import GoogleLogin from './GoogleLogin';

import { prefix } from '../siteConfig';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { UserContext } from '../index'; 

import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
}
    from 'mdb-react-ui-kit';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    divider: {
        backgroundColor: 'white',
    },
}));

function App() {
    const classes = useStyles();
    const googleLogin = GoogleLogin();
    const [errorMessage, setErrorMessage] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();

    const userContext =  useContext(UserContext);

    // setCTX(prevCTX => ({ ...prevCTX, ...data }));
    const fetchUser = async () => {
        try {
            const response = await fetch(`${prefix}user_management/getUserAccess`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            if (!data.error && data) {
                userContext.setCTX(prevCTX => ({ ...prevCTX, ...data }));
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        console.log("userContext in login changed")

        console.log(userContext)


    },[userContext])

    
    const handleLogin = async () => {
        try {
          const response = await fetch(`${prefix}user_management/userLogin`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: email,
              password: password
            }),
          });
      
          if (!response.ok) {
            const message = await response.text();
            throw new Error(message);
          }
      
          const data = await response.json();
      
          if (data.status == 'success') {
            await fetchUser();
            navigate('/admin/learn')
          } else {
            toast.error(data.error);
          }
        } catch (error) {
          console.error('Error:', error);
          toast.error('An error occurred. Please try again.');
        }
      };


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has('error')) {
            console.log('Error:', queryParams.get('error'));
            setErrorMessage(true);
        }
    }, [location.search]);
    return (
        <MDBContainer className="LoginForm" fluid>

            <MDBRow className='d-flex justify-content-center h-100'>
                <MDBCol col='12'>
                    <MDBCard className='bg-dark text-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '400px' }}>
                        <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

                            <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
                            <p className="text-white-50 mb-4">Use your Google account to log in to learnleetcode.com</p>
                            <Button
                                fullWidth
                                size="large"
                                color="primary"
                                variant="outlined"
                                onClick={async () => {
                                    try {
                                        await googleLogin();
                                    } catch (error) {
                                        console.log('ErrorLogin:', error);
                                    }
                                }}
                            >
                                <Iconify icon="eva:google-fill" color="#DF3E30" />
                            </Button>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', margin: '20px 0' }}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Divider style={{ backgroundColor: 'darkgrey' }} />
                                </Box>
                                <Box sx={{ padding: '0 10px' }}>
                                    <Typography variant="caption" color="white">or</Typography>
                                </Box>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Divider style={{ backgroundColor: 'darkgrey' }} />
                                </Box>
                            </Box>
                            <MDBInput wrapperClass='mb-4 mx-5 w-100' inputStyle={{ padding: '10px' }} labelClass='text-white' label='Email address' id='formControlLg' type='email' size="lg" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <MDBInput wrapperClass='mb-4 mx-5 w-100' inputStyle={{ padding: '10px' }} labelClass='text-white' label='Password' id='formControlLg' type='password' size="lg" value={password} onChange={(e) => setPassword(e.target.value)} />

                            {/* <p className="small mb-3 pb-lg-2"><a class="text-white-50" href="#!">Forgot password?</a></p> */}

                            <div style={{ height: '20px', color: 'red' }}>
                                {errorMessage}
                            </div>
                            <MDBBtn onClick={handleLogin} outline className='mx-2 px-5 login-button' color='white' size='lg'>
                                Login
                            </MDBBtn>
                            <div>
                                <p className="mb-0">Don't have an account? <Link to="/signup" className="text-white-50 fw-bold">Sign Up</Link></p>
                            </div>
                        </MDBCardBody>
                    </MDBCard>

                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export default App;
