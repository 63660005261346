import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { prefix } from "../siteConfig";
import { useLocation } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import { Divider, Select, MenuItem, CircularProgress, Box, FormLabel, FormControl, InputLabel, Checkbox, ListItemText, FormGroup, FormControlLabel, TextField } from '@mui/material';
import Grid from '@material-ui/core/Grid'; // Ensure you import Grid from Material-UI
import Button from '@mui/material/Button';
import {
  // Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  CardText,
  // FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label

} from "reactstrap";


function Emailer(props) {
  const [leetCodeProblems, setLeetCodeProblems] = useState([]);
  const [filteredProblems, setFilteredProblems] = useState([]);
  const [filterListType, setFilterListType] = useState(['Blind75']);
  const [filterDifficulty, setFilterDifficulty] = useState({
    "Easy": true,
    "Medium": true,
    "Hard": true
  });
  const patternOrder = ['Arrays & Hashing', 'Two Pointers', 'Stack', 'Binary Search', 'Sliding Window', 'Linked List', 'Trees', 'Tries', 'Heap / Priority Queue', 'Graphs', 'Backtracking', 'Intervals', 'Greedy', 'Advanced Graphs', '1-D Dynamic Programming', '2-D Dynamic Programming', 'Bit Manipulation', 'Math & Geometry', 'Uncategorized'];
  const [filterPattern, setFilterPattern] = useState([...patternOrder]);
  const [studyPerDay, setStudyPerDay] = useState(1);
  const [problemCount, setProblemCount] = useState();
  const [daysToFinish, setDaysToFinish] = useState(0);
  const [dateOfFinish, setDateOfFinish] = useState("");
  const [checkedProblems, setCheckedProblems] = useState([]);
  const [selectAllCheckedProblems, setSelectAllCheckedProblems] = useState(false)

  const [selectAllCheckedProblemsLabel, setSelectAllCheckedProblemsLabel] = useState("select all")

  const [selectAllCheckedPatterns, setSelectAllCheckedPatterns] = useState(true)
  const [selectAllCheckedPatternsLabel, setSelectAllCheckedPatternsLabel] = useState("select all")
  const [scheduleName, setScheduleName] = useState('');
  const [searchTerm, setSearchTerm] = React.useState('');

  const [displayedProblems, setDisplayedProblems] = useState([])


  useEffect(() => {
    console.log("select All checked problems");
    if (selectAllCheckedProblems) {
      setCheckedProblems(filteredProblems.map(problem => problem.id));
      // setSelectAllCheckedProblemsLabel("select none")
    } else {
      setCheckedProblems([]);
      // setSelectAllCheckedProblemsLabel("select all")
    }
  }, [selectAllCheckedProblems, filteredProblems]);

  useEffect(() => {
    setDisplayedProblems(filteredProblems)
  }, [filteredProblems]);

  const handleCheckboxChange = (problemId, isChecked) => {
    setCheckedProblems(current => {
      if (isChecked) {
        return [...current, problemId];
      } else {
        return current.filter(id => id !== problemId);
      }
    });
  };

  useEffect(() => {
    setProblemCount(checkedProblems.length);
    // To log the updated problemCount, consider using another useEffect that depends on problemCount
  }, [checkedProblems]);

  useEffect(() => {
    // Assuming defaultChecked means all filteredProblems are initially checked
    // setCheckedProblems(filteredProblems.map(problem => problem.id));
    console.log("filteredProblems");
    console.log(filteredProblems);
    if (!selectAllCheckedPatterns) {
      setFilterPattern([])
    } else {
      setFilterPattern(patternOrder)
    }

  }, [selectAllCheckedPatterns]);

  useEffect(() => {
    getProblems();
  }, []);

  useEffect(() => {
    console.log(selectAllCheckedPatterns)

  }, [selectAllCheckedPatterns]);

  useEffect(() => {
    const filtered = leetCodeProblems.filter(problem => {
      const listTypeArray = Array.isArray(filterListType) ? filterListType : [filterListType];
      const listTypeConditions = listTypeArray.map(type => {
        if (type === 'NeetCode150') return problem.neetcode150 !== null;
        if (type === 'Blind75') return problem.blind75 !== null;
        return false;
      });

      const isListTypeMatch = listTypeConditions.some(condition => condition);
      const isDifficultyMatch = filterDifficulty[problem.difficulty];
      // Adjust isPatternMatch to return true if selectAllCheckedPatterns is true
      const isPatternMatch = selectAllCheckedPatterns || filterPattern.includes(problem.pattern) || filterPattern.includes('All');

      // const isSearchMatch = searchTerm.trim() === '' || problem.problem.toLowerCase().includes(searchTerm.trim().toLowerCase());

      return isListTypeMatch && isDifficultyMatch && isPatternMatch;
      //return problem.isChecked || (isListTypeMatch && isDifficultyMatch && isPatternMatch && isSearchMatch);

    });

    setFilteredProblems(filtered);
  }, [leetCodeProblems, filterListType, filterDifficulty, filterPattern]);


  useEffect(() => {
    console.log('searchTerm')
    console.log(searchTerm)


    const filtered = filteredProblems.filter(problem => {
      console.log(problem)
      const isSearchMatch = searchTerm.trim() === '' || problem.problem.toLowerCase().includes(searchTerm.trim().toLowerCase());
      return isSearchMatch || problem.isChecked
    });

    console.log(filtered)

    setDisplayedProblems(filtered);
  }, [searchTerm]);



  useEffect(() => {
    const totalDays = Math.ceil(problemCount / studyPerDay) + 16; // Calculate total days required, rounding up

    const finishDate = new Date(); // Get today's date
    finishDate.setDate(finishDate.getDate() + totalDays); // Calculate the finish date

    console.log(`Total days required: ${totalDays}`);
    console.log(`You will finish on: ${finishDate.toDateString()}`);

    setDaysToFinish(totalDays)
    setDateOfFinish(finishDate.toDateString())
  }, [problemCount, studyPerDay]);


  const getProblems = async () => {
    const response = await fetch(`${prefix}leetcode/getProblems`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      const data = await response.json();
      setLeetCodeProblems(data);
      setFilteredProblems(data); // Initialize filtered problems with all problems
    }
  };

  const handleListTypeChange = (event, value) => {
    const isChecked = event.target.checked;

    // Add the value if checked, remove if unchecked
    let newListType = [...filterListType];
    if (isChecked) {
      // Add only if it's not already in the array
      if (!newListType.includes(value)) {
        newListType.push(value);
      }
    } else {
      // Remove the value
      newListType = newListType.filter(type => type !== value);
    }

    // Deduplicate newListType just in case (optional if logic above is correct)
    newListType = [...new Set(newListType)];

    setFilterListType(newListType);
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setFilterDifficulty(prevState => ({
      ...prevState,
      [name]: checked,
    }));
  };

  async function scheduleEmails() {
    // Data to be sent in the POST request
    if (!scheduleName) {
      toast.error("Schedule name is required")
      return
    }
    const filteredProblemsArray = filteredProblems.map(problem => problem.id)
    const postData = {
      studyPerDay: studyPerDay,
      problems: checkedProblems,
      scheduleName: scheduleName
    };

    // Using Fetch API to send a POST request to the backend
    fetch(`${prefix}content_personalization/addEmailSchedule`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          toast.error(data.error);
        } else {
          console.log('Success:', data);
          toast("Success! Added schedule. Your first email will be today or tomorrow.");
          setSelectAllCheckedProblems(false)
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        // Handle errors here (e.g., showing an error message)
      });
  }

  return (
    <div className="content">
      <Row> {/* Use Row to create a row */}
        <Col md="5">
          <Card>
            <CardBody>
              <CardText>

                <FormControl component="fieldset" fullWidth>
                  <FormLabel
                    sx={{
                      mt: 5, // Maintain top margin for spacing
                      fontWeight: 'bold', // Make the font bold for better emphasis
                      color: 'primary.dark', // Use the primary color from the theme
                      fontSize: '1.2rem', // Increase the font size for better readability
                      letterSpacing: '0.5px', // Add some letter spacing for a polished look
                    }}
                    component="legend">Step 1: Choose List Type</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={filterListType.includes('Blind75')} onChange={e => handleListTypeChange(e, 'Blind75')} />}
                      label="Blind75"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={filterListType.includes('NeetCode150')} onChange={e => handleListTypeChange(e, 'NeetCode150')} />}
                      label="NeetCode150"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl fullWidth>
                  <FormLabel
                    sx={{
                      mt: 5, // Maintain top margin for spacing
                      fontWeight: 'bold', // Make the font bold for better emphasis
                      color: 'primary.dark', // Use the primary color from the theme
                      fontSize: '1.2rem', // Increase the font size for better readability
                      letterSpacing: '0.5px', // Add some letter spacing for a polished look
                    }}
                    component="legend">Step 2: Choose Difficulty</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={filterDifficulty.Easy} onChange={handleChange} name="Easy" />}
                      label="Easy"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={filterDifficulty.Medium} onChange={handleChange} name="Medium" />}
                      label="Medium"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={filterDifficulty.Hard} onChange={handleChange} name="Hard" />}
                      label="Hard"
                    />
                  </FormGroup>
                </FormControl>

                <FormLabel
                  sx={{
                    mt: 5, // Maintain top margin for spacing
                    fontWeight: 'bold', // Make the font bold for better emphasis
                    color: 'primary.dark', // Use the primary color from the theme
                    fontSize: '1.2rem', // Increase the font size for better readability
                    letterSpacing: '0.5px', // Add some letter spacing for a polished look
                  }}
                  component="legend">Step 3: Choose Pattern</FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAllCheckedPatterns}
                      onChange={(e) => setSelectAllCheckedPatterns(e.target.checked)}
                    />
                  }
                  label={selectAllCheckedPatternsLabel}
                />
                <Box sx={{
                  height: '400px',
                  maxWidth: '500px',
                  overflowY: 'auto',
                  border: '1px solid #333', // Darker border for dark mode
                  padding: '8px',
                  backgroundColor: '#121212', // Dark background
                  color: '#fff',
                  '& .MuiGrid-spacing-xs-1 > .MuiGrid-item': {
                    padding: '0 !important',
                  },
                }}>
                  <FormControl component="fieldset" fullWidth>
                    <FormGroup>
                      <Grid container spacing={1}>
                        {patternOrder.map(pattern => (
                          <Grid item xs={12} key={pattern} sx={{ minHeight: '30px' }}> {/* Reduced row height */}
                            <FormControlLabel
                              sx={{ padding: '0 !important', paddingLeft: "10px", color: '#fff', marginBottom: 0 }}
                              control={<Checkbox
                                checked={filterPattern.includes(pattern)}
                                onChange={e => {
                                  setFilterPattern(current => {
                                    if (e.target.checked) {
                                      return current.includes(pattern) ? current : [...current, pattern];
                                    } else {
                                      return current.filter(item => item !== pattern);
                                    }
                                  });
                                }}
                                name={pattern}
                                sx={{
                                  color: '#fff', // Light text for checkbox label
                                  '&.Mui-checked': {
                                    color: '#90caf9', // Lighter blue for checked state
                                  },
                                  marginLeft: '10px !important'
                                }}
                              />}
                              label={pattern}
                            />
                            <Divider />
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </FormControl>
                </Box>

                <FormLabel
                  sx={{
                    mt: 5, // Maintain top margin for spacing
                    fontWeight: 'bold', // Make the font bold for better emphasis
                    color: 'primary.main', // Use the primary color from the theme
                    fontSize: '1.2rem', // Increase the font size for better readability
                    letterSpacing: '0.5px', // Add some letter spacing for a polished look
                  }}
                  component="legend">Step 4: Choose Questions</FormLabel>
                <Box sx={{
                  display: 'flex', alignItems: 'center', mb: 2, width: '500px'
                }}>
                  <TextField
                    label="Search Problems"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{
                      backgroundColor: '#333', // Dark theme background color
                      color: '#fff', // Text color for dark theme
                      '.MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#777', // Border color for dark theme
                        },
                        '&:hover fieldset': {
                          borderColor: '#bbb', // Border color on hover for dark theme
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#fff', // Border color when focused for dark theme
                        },
                        height: '40px', // Reduce the height of the TextField
                      },
                      '.MuiInputLabel-root': {
                        color: '#aaa', // Label color for dark theme
                        top: '-6px', // Adjust label position to fit the reduced height
                      },
                      '.MuiInputBase-input': {
                        color: '#fff', // Input text color for dark theme
                        padding: '10px 14px', // Reduce padding to decrease height
                      },
                      width: 'auto', // Remove fullWidth to make it small
                      marginRight: 2, // Add some space between the text field and the checkbox
                    }}
                  />
                  <FormControlLabel
                    style={{
                      marginLeft: 0,
                      marginRight: 0,
                      marginBottom: 0
                    }}
                    control={
                      <Checkbox
                        checked={selectAllCheckedProblems}
                        onChange={(e) => setSelectAllCheckedProblems(e.target.checked)}
                      />
                    }
                    label={selectAllCheckedProblemsLabel}
                  />
                </Box>
                <Box sx={{
                  height: '400px',
                  maxWidth: '500px',
                  overflowY: 'auto',
                  border: '1px solid #333',
                  padding: '8px',
                  backgroundColor: '#121212',
                  color: '#fff',
                  '& .MuiGrid-spacing-xs-1 > .MuiGrid-item': {
                    padding: '0 !important',
                  },
                }}>
                  <FormControl component="fieldset" fullWidth>
                    <FormGroup>
                      <Grid container spacing={1}> {/* Adjusted spacing */}
                        {/* {filteredProblems.length > 0 ? filteredProblems.map(problem => ( */}
                        {displayedProblems.length > 0 ? displayedProblems.map(problem => (
                          <Grid item xs={12} key={problem.id} sx={{ minHeight: '30px' }}> {/* Reduced row height */}
                            <FormControlLabel
                              sx={{ padding: '0 !important', paddingLeft: "10px", color: '#fff', marginBottom: 0 }}
                              control={
                                <Checkbox
                                  checked={checkedProblems.includes(problem.id)}
                                  onChange={(e) => handleCheckboxChange(problem.id, e.target.checked)}
                                  sx={{
                                    color: '#fff', // Light text for checkbox label
                                    '&.Mui-checked': {
                                      color: '#90caf9', // Lighter blue for checked state
                                    },
                                    marginLeft: '10px !important',

                                  }} />}
                              label={problem.problem}
                            />
                            <Divider />
                          </Grid>
                        )) : <p>No problems selected.</p>}
                      </Grid>
                    </FormGroup>
                  </FormControl>
                </Box>


                <FormLabel sx={{ mt: 2 }} component="legend">Number of new problems to study per day</FormLabel>
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    labelId="study-per-day-label"
                    id="study-per-day"
                    value={studyPerDay}
                    onChange={e => setStudyPerDay(e.target.value)}
                    displayEmpty
                    sx={{ padding: '8px', height: '40px' }}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    {/* Add more options as needed */}
                  </Select>
                </FormControl>

                {problemCount > 0 ? (
                  <Box
                    mt={2}
                    mb={3}
                  >
                    <p><h4>selected {problemCount} problems</h4></p>
                    <p><h4>It will take {daysToFinish} days to finish this study schedule, finishing on {dateOfFinish}</h4></p>
                    <p>*with each problem reviewed 4 times at a tapering interval</p>
                    <TextField
                      required
                      id="schedule-name"
                      label="Schedule Name"
                      value={scheduleName}
                      onChange={e => setScheduleName(e.target.value)}
                      margin="normal"
                      fullWidth
                      style={{ marginTop: '24px', maxWidth: '500px' }}
                    />
                    <div>
                      <Button sx={{ mt: 3 }} variant="contained" color="primary" onClick={scheduleEmails}>
                        Schedule Emails
                      </Button>
                    </div>
                  </Box>
                ) : (
                  <p style={{ marginTop: '2rem' }}>Select problems to schedule review emails.</p>
                )}
                <br/>
                <br/>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
export default Emailer;
