import React, { useEffect, useState, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { initializeApp, getApps } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import firebaseConfig from "../firebase";

import { prefix } from '../siteConfig';

import { toast } from 'react-toastify';
import { UserContext } from '../index'; 

let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

const provider = new GoogleAuthProvider();
const auth = getAuth(app); // Pass the initialized app to getAuth


// export const GoogleLogin = (callback="/admin/learn") => {
//   const navigate = useNavigate();

//   return async () => {
//     try {
//       const result = await signInWithPopup(auth, provider);
//       const { user } = result;
//       const uid = user.uid; // Get user's UID
//       const email = user.email; // Get user's email

//       fetch(`${prefix}user_management/loginWithGoogle`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ uid, email }), 
//       })
//         .then(response => response.json())
//         .then(data => {
//           if (data.status !== 'success') {
//               toast.error('Error: ' + data.error);
//             } else {
//             await fetchUser()
//             navigate(callback);
//           }
//          })
//         .catch((error) => {
//           console.error('ErrorGoogle:', error);
//         });
//     } catch (error) {
//       console.error(error);
//     }
//   };
// };


const fetchUser = async (userContext) => {

  try {
    const response = await fetch(`${prefix}user_management/getUserAccess`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    console.log("data: ", data)
    if (!data.error && data) {
      // userContext.setCTX(data)
      userContext.setCTX(prevCTX => ({ ...prevCTX, ...data }));

    } 
  } catch (error) {
  }
};

export const GoogleLogin = (callback = "/admin/learn") => {
  const navigate = useNavigate();
  const userContext =  useContext(UserContext);


  return async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const { user } = result;
      const uid = user.uid; // Get user's UID
      const email = user.email; // Get user's email

      const response = await fetch(`${prefix}user_management/loginWithGoogle`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid, email }),
      });

      const data = await response.json();

      if (data.status !== 'success') {
        toast.error('Error: ' + data.error);
      } else {
        await fetchUser(userContext);
        navigate(callback);
      }
    } catch (error) {
      console.error('ErrorGoogle:', error);
    }
  };
};

// export const GoogleSignup = (callback = "/admin/learn") => {
//   const navigate = useNavigate();
//   const userContext  = useContext(UserContext);

  
//   return async () => {
//     try {
//       const result = await signInWithPopup(auth, provider);
//       const { user } = result;
//       const uid = user.uid;
//       const email = user.email;

      

//       fetch(`${prefix}user_management/signupWithGoogle`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ uid, email }),
//       })
//         .then(response => response.json())
//         .then(data => {
//           if (data.status !== 'success') {
//             toast.error('Error: ' + data.error);
//           } else {
//             // updateCTX({email:email})
//             toast.success('Welcome to LearnLeetCode. Free trial for 30 days. Get started by choosing a problem and clicking Learn.');
//             navigate(callback + `?email=${email}`
//             );
//           }
//         })
//         .catch((error) => {
//           toast.error('Error: ' + error);
//         });
//     } catch (error) {
//       console.error(error);
//       toast.error('Error: ' + error);
//     }
//   };
// };

export const GoogleSignup = (callback = "/admin/learn") => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext); // Consider using if needed

  const handleSignup = async () => {
    try {
      const { user } = await signInWithPopup(auth, provider);
      await postSignup({ uid: user.uid, email: user.email });
    } catch (error) {
      console.error(error);
      toast.error('Error: ' + error.message);
    }
  };

  const postSignup = async ({ uid, email }) => {
    try {
      const response = await fetch(`${prefix}user_management/signupWithGoogle`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid, email }),
      });
      const data = await response.json();
      if (data.status !== 'success') {
        toast.error('Error: ' + data.error);
      } else {
        await fetchUser(userContext);
        toast.info(
          'Welcome to LearnLeetCode: get started by choosing a problem and clicking Learn.',
          {
            autoClose: false,
            closeOnClick: false,
            pauseOnHover: false,
          }
        );
        navigate(callback);
      }
    } catch (error) {
      toast.error('Error: ' + error.message);
    }
  };

  return handleSignup;
};

export default GoogleLogin;