import React, { useInsertionEffect } from "react";
import { createContext, useState, useEffect, useContext } from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { initializeApp, getApps } from "firebase/app";
import { getAuth,onAuthStateChanged  } from "firebase/auth";
import firebaseConfig from "./firebase";

import Admin from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Login from "views/Login";
import SignUp from "views/SignUp";
import Dashboard from "views/Dashboard";
import TrialEnd from "views/TrialEnd"
import { CheckoutForm, Return } from "views/PayStripe";
// import Checkout from "views/Checkout";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { prefix } from './siteConfig';

const UserContext = createContext();

function App() {
  const [email, setEmail] = useState(null)
  const [access, setAccess] = useState({});
  const [ctx, setCTX] = useState({}); 
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true); // Begin loading

      try {
        const response = await fetch(`${prefix}user_management/getUserAccess`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (!data.error && data) {
          console.log("in protected route before setCTX")
          console.log(setCTX)
          setCTX(() => data);
        } 
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchUser();
  }, []); 


  const ProtectedRoute = ({ children }) => {
    const { ctx } = useContext(UserContext);
    if (isLoading) return <div>Loading...</div>; 
    // const shouldRedirect = !ctx || !ctx.email;
    if (ctx && ctx.email && !ctx.has_access) {
      console.log("CTX in Protexted route")
      console.log(ctx)
      console.log("NAVIGATE TO /TRIAL-END")
      return <Navigate to="/trial-end" replace={true} />;
    } else if (!ctx || !ctx.email) {
      return <Navigate to="/login" replace={true} />;
    } else {
      return children;
    }
    // return shouldRedirect ? <Navigate to="/login" replace={true} /> : children;
  };

  // const ProtectedRoute = ({ children }) => {
  //   const { ctx, isLoading } = useContext(UserContext);
  
  //   if (isLoading) {
  //     return <div>Loading...</div>;
  //   }
  
  //   if (!ctx || !ctx.email) {
  //     return <Navigate to="/login" replace={true} />;
  //   }
  
  //   if (!ctx.has_access) {
  //     return <Navigate to="/trial-ended" replace={true} />;
  //   }
  
  //   return children;
  // };

  return (
    <ThemeContextWrapper>
      <BackgroundColorWrapper>
      <UserContext.Provider value={{ ctx, setCTX }}>
            <>
              <BrowserRouter>
                <Routes>
                  <Route path="/trial-end" element={<TrialEnd />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/signup/checkout" element={<CheckoutForm />} />
                  <Route path="/signup/return" element={<Return />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/admin/*" element={<ProtectedRoute><Admin /></ProtectedRoute>} />
                  <Route path="/rtl/*" element={<ProtectedRoute><RTLLayout /></ProtectedRoute>} />
                  <Route
                    path="/*"
                    element={<ProtectedRoute><Admin /></ProtectedRoute>} />
                </Routes>
              </BrowserRouter>
              <ToastContainer />
            </>
        </UserContext.Provider>
      </BackgroundColorWrapper>
    </ThemeContextWrapper>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
export { UserContext };