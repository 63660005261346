import { useNavigate } from 'react-router-dom';

import React, { useCallback, useState, useEffect, useContext } from "react";

import { prefix } from "siteConfig";
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";

import { useLocation } from 'react-router-dom';

import { stripeKeyPublishable } from '../siteConfig.js';
import { UserContext } from '../index';
import { ConstructionOutlined } from '@mui/icons-material';


const stripePromise = loadStripe(stripeKeyPublishable);

const CheckoutForm = ({ email }) => {
  const location = useLocation();
  const userContext = useContext(UserContext);
  const params = new URLSearchParams(location.search);
  const [status, setStatus] = useState('none')
  const [session, setSession] = useState({})
  const [triggerFetch, setTriggerFetch] = useState(false);

  const fetchClientSecret = useCallback(async () => {
    const res = await fetch(`${prefix}user_management/createStripeCheckoutSession`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: email })
    });
    const data = await res.json();
    setSession(data);
    return data.session.client_secret;
  }, [email]);



  useEffect(() => {
    if (triggerFetch && session && session.session) {
      fetch(`${prefix}user_management/stripeSessionStatus?session_id=${session.session.id}`)
        .then((res) => res.json())
        .then((data) => {
          setTimeout(() => setStatus(data.status), 10000);
          // Now call getUserAccess after setting the status
          return fetch(`${prefix}user_management/getUserAccess`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
        })
        .then(response => response.json())
        .then(data => {
          if (!data.error && data) {
            userContext.setCTX(prevCTX => ({ ...prevCTX, ...data }));
          }
        })
        .catch(error => console.error('Error:', error));
    }
  }, [triggerFetch, session])

  const handleComplete = () => {
    setTriggerFetch(true)
  }

  const options = { fetchClientSecret };

  // Conditional rendering based on email
  if (!email) {
    return <div>Loading...</div>; // Or return null; if you don't want to show anything
  }

  return (
    <div id="checkout" style={{ width: '100%' }}>
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{
          ...options,
          onComplete: handleComplete
        }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}
export { CheckoutForm };

const Return = () => {
  const [status, setStatus] = useState(null);
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    // fetch(`${prefix}user_management/stripeSessionStatus?session_id=${sessionId}`)
    //   .then((res) => res.json())
    //   .then((data) => {

    //     setStatus(data.status);
    //   });

    fetch(`${prefix}user_management/stripeSessionStatus?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        // etStatus(data.status);
        setTimeout(() => setStatus(data.status), 10000);
      });


  }, []);

  useEffect(() => {
    if (status === 'open' || !status) {
      console.log("status is open")
    }
    if (status === 'complete') {
      fetchUserAccess()
    }
  }, [status])


  useEffect(() => {
    if (status === 'open') {
      console.log("status is open, in usercontext")
    }
    if (status === 'complete') {
      console.log("status is complete")
    }
  }, [userContext])


  const fetchUserAccess = async () => {
    try {
      const response = await fetch(`${prefix}user_management/getUserAccess`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (!data.error && data) {
        userContext.setCTX(data)
      }
    } catch (error) {
      console.log("error from fetch user access")
      console.log(error)
    }
  };

  return null;
}

export { Return };

const PayStripe = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/signup/checkout" element={<CheckoutForm />} />
          <Route path="/signup/return" element={<Return />} />
        </Routes>
      </Router>
    </div>
  );
};

export default PayStripe;